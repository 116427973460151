import { call, put, takeLeading } from 'redux-saga/effects';
import { getCities } from '../../services/cities';
import ActionHelper from '../../utils/action-helper';
import CITY_ACTION_TYPES from '../actions/CITY_ACTION_TYPES';

/**
 * Get City from backend
 */
export function* getCityList({ payload: input }) {
  try {
    const response = yield call(getCities, input);
    yield put(ActionHelper.fire(CITY_ACTION_TYPES.LIST_SUCCESS, response));
  } catch (error) {
    console.log('Error fetching cities:', error);
    yield put(
      ActionHelper.fire(CITY_ACTION_TYPES.LIST_FAILURE, error.toString())
    );
  }
}

export default function* cityList() {
  yield takeLeading(CITY_ACTION_TYPES.LIST_REQUEST, getCityList);
}
