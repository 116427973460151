import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import FormInput from './formInput';
import { DatePicker } from '@mui/x-date-pickers';
import { useField } from 'formik';

// eslint-disable-next-line
const FormDatepicker = forwardRef((props, ref) => {
  const [field, meta] = useField(props);

  return (
    <DatePicker
      {...field}
      {...props}
      ref={ref}
      slots={{
        textField: FormInput,
      }}
      slotProps={{
        textField: {
          error: meta.touched && Boolean(meta.error),
          helperText: meta.touched && meta.error,
        },
      }}
    />
  );
});

FormDatepicker.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  // other properties are the same as MUI Autocomplete
};

export default FormDatepicker;
