import { call, put, takeLeading } from 'redux-saga/effects';
import { getTechnologies } from '../../services/technologies';
import ActionHelper from '../../utils/action-helper';
import TECHNOLOGY_ACTION_TYPES from '../actions/TECHNOLOGY_ACTION_TYPES';

/**
 * Get Technolofies from backend
 */
export function* getTechnologyList() {
  try {
    const response = yield call(getTechnologies, {});
    yield put(
      ActionHelper.fire(TECHNOLOGY_ACTION_TYPES.LIST_SUCCESS, response)
    );
  } catch (error) {
    console.log('Error fetching technologies:', error);
    yield put(
      ActionHelper.fire(TECHNOLOGY_ACTION_TYPES.LIST_FAILURE, error.toString())
    );
  }
}

export default function* technologyList() {
  yield takeLeading(TECHNOLOGY_ACTION_TYPES.LIST_REQUEST, getTechnologyList);
}
