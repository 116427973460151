import { combineReducers } from 'redux';

import cityList from './cityList';
import provinceList from './provinceList';
import countryList from './countryList';

import educationList from './educationList';
import methodologyList from './methodologyList';
import languageList from './languageList';
import technologyList from './technologyList';

import offerAdd from './offerAdd';

import certificationList from './certificationList';
import profileList from './profileList';

import professionalAdd from './professionalAdd';

const reducers = combineReducers({
  cityList,
  provinceList,
  countryList,
  educationList,
  methodologyList,
  technologyList,
  languageList,
  offerAdd,
  certificationList,
  profileList,
  professionalAdd,
});

export default reducers;
