import fetchData from '.';

const sendContactService = (user) => {
  return fetchData('/email', {
    method: 'POST',
    body: JSON.stringify(user),
  });
};

export { sendContactService };
