import fetchData from '.';

const createProfessionals = (professional) => {
  return fetchData(`/professionals`, {
    method: 'POST',
    body: JSON.stringify(professional),
  });
};

export { createProfessionals };
