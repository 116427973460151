import { STATE } from '../../utils/consts';
import PROFESSIONAL_ACTION_TYPES from '../actions/PROFESSIONAL_ACTION_TYPES';

const defaultState = {
  state: STATE.NONE,
  data: [],
};

const ACTION_HANDLERS = {
  [PROFESSIONAL_ACTION_TYPES.ADD_SET_STATE]: (state, action) => ({
    ...state,
    ...action.payload,
    state: STATE.NONE,
    data: [],
  }),
  [PROFESSIONAL_ACTION_TYPES.ADD_REQUEST]: (state) => ({
    ...state,
    state: STATE.FETCHING,
  }),
  [PROFESSIONAL_ACTION_TYPES.ADD_SUCCESS]: (state, action) => ({
    ...state,
    data: action.payload,
    state: STATE.SUCCESS,
    error: null,
  }),
  [PROFESSIONAL_ACTION_TYPES.ADD_FAILURE]: (state, action) => {
    const { payload: error } = action;
    return {
      ...state,
      state: STATE.FAILURE,
      error,
    };
  },
};

export default (state = defaultState, action = STATE.NONE) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};
