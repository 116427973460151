import { call, put, takeLeading } from 'redux-saga/effects';
import { getMethodologies } from '../../services/methodologies';
import ActionHelper from '../../utils/action-helper';
import METHODOLOGY_ACTION_TYPES from '../actions/METHODOLOGY_ACTION_TYPES';

/**
 * Get Methodologies from backend
 */
export function* getMethodologieList() {
  try {
    const response = yield call(getMethodologies, {});
    yield put(
      ActionHelper.fire(METHODOLOGY_ACTION_TYPES.LIST_SUCCESS, response)
    );
  } catch (error) {
    console.log('Error fetching methodologies:', error);
    yield put(
      ActionHelper.fire(METHODOLOGY_ACTION_TYPES.LIST_FAILURE, error.toString())
    );
  }
}

export default function* methodologyList() {
  yield takeLeading(METHODOLOGY_ACTION_TYPES.LIST_REQUEST, getMethodologieList);
}
