import { STATE } from '../../utils/consts';
import CERTIFICATION_ACTION_TYPES from '../actions/CERTIFICATION_ACTION_TYPES';

const defaultState = {
  state: STATE.NONE,
  data: [],
};

const ACTION_HANDLERS = {
  [CERTIFICATION_ACTION_TYPES.SET_STATE]: (state, action) => ({
    ...state,
    ...action.payload,
    state: STATE.NONE,
  }),
  [CERTIFICATION_ACTION_TYPES.LIST_REQUEST]: (state) => ({
    ...state,
    state: STATE.FETCHING,
  }),
  // eslint-disable-next-line
  [CERTIFICATION_ACTION_TYPES.LIST_SUCCESS]: (state, action) => {
    return {
      ...state,
      loadedAt: new Date().toISOString(),
      data: action.payload,
      state: STATE.SUCCESS,
      error: null,
    };
  },
  [CERTIFICATION_ACTION_TYPES.LIST_FAILURE]: (state, action) => {
    const { payload: error } = action;
    return {
      ...state,
      state: STATE.FAILURE,
      error,
    };
  },
};

export default (state = defaultState, action = STATE.NONE) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};
