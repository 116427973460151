import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import * as styles from './style.module.scss';

const FormRadio = ({ label, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <div>
      <label className={styles.radioLabel}>
        <input
          type="radio"
          {...field}
          {...props}
          checked={field.value === props.value}
          className={`${styles.radio} ${meta.touched && meta.error ? styles.error : ''}`}
        />
        {label}
      </label>
      {meta.touched && meta.error ? (
        <div className={styles.error}>{meta.error}</div>
      ) : null}
    </div>
  );
};

FormRadio.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  id: PropTypes.string,
};

export default FormRadio;
