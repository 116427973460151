import { call, put, takeLeading } from 'redux-saga/effects';
import { getCertifications } from '../../services/certifications';
import ActionHelper from '../../utils/action-helper';
import CERTIFICATION_ACTION_TYPES from '../actions/CERTIFICATION_ACTION_TYPES';

/**
 * Get Certification from backend
 */
export function* getCertificationList({ payload: input }) {
  try {
    const response = yield call(getCertifications, input);
    yield put(
      ActionHelper.fire(CERTIFICATION_ACTION_TYPES.LIST_SUCCESS, response)
    );
  } catch (error) {
    console.log('Error fetching certifications:', error);
    yield put(
      ActionHelper.fire(
        CERTIFICATION_ACTION_TYPES.LIST_FAILURE,
        error.toString()
      )
    );
  }
}

export default function* certificationList() {
  yield takeLeading(
    CERTIFICATION_ACTION_TYPES.LIST_REQUEST,
    getCertificationList
  );
}
