import { call, put, takeLeading } from 'redux-saga/effects';
import { getEducations } from '../../services/educations';
import ActionHelper from '../../utils/action-helper';
import EDUCATION_ACTION_TYPES from '../actions/EDUCATION_ACTION_TYPES';

/**
 * Get Educations from backend
 */
export function* getEducationList() {
  try {
    const response = yield call(getEducations, {});
    yield put(ActionHelper.fire(EDUCATION_ACTION_TYPES.LIST_SUCCESS, response));
  } catch (error) {
    console.log('Error fetching educations:', error);
    yield put(
      ActionHelper.fire(EDUCATION_ACTION_TYPES.LIST_FAILURE, error.toString())
    );
  }
}

export default function* educationList() {
  yield takeLeading(EDUCATION_ACTION_TYPES.LIST_REQUEST, getEducationList);
}
