import fetchData from '.';

const createOffers = (offer) => {
  return fetchData(`/offers`, {
    method: 'POST',
    body: JSON.stringify(offer),
  });
};

export { createOffers };
