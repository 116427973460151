import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import * as styles from './style.module.scss';

const FormCheckbox = ({ children, ...props }) => {
  const [field, meta] = useField({ ...props, type: 'checkbox' });

  return (
    <div className={styles.formControl}>
      <label className={styles.checkboxLabel}>
        <input
          type="checkbox"
          {...field}
          {...props}
          className={`${styles.checkbox} ${meta.touched && meta.error ? styles.error : ''}`}
        />
        {children}
      </label>
      {meta.touched && meta.error ? (
        <div className={styles.error}>{meta.error}</div>
      ) : null}
    </div>
  );
};

FormCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  id: PropTypes.string,
};

export default FormCheckbox;
