import { call, put, takeLeading } from 'redux-saga/effects';
import { getCountries } from '../../services/countries';
import ActionHelper from '../../utils/action-helper';
import COUNTRY_ACTION_TYPES from '../actions/COUNTRY_ACTION_TYPES';

/**
 * Get Countries from backend
 */
export function* getCountryList() {
  try {
    const response = yield call(getCountries, {});
    yield put(ActionHelper.fire(COUNTRY_ACTION_TYPES.LIST_SUCCESS, response));
  } catch (error) {
    console.error('Error fetching countries:', error);
    yield put(
      ActionHelper.fire(COUNTRY_ACTION_TYPES.LIST_FAILURE, error.toString())
    );
  }
}

export default function* countryList() {
  yield takeLeading(COUNTRY_ACTION_TYPES.LIST_REQUEST, getCountryList);
}
