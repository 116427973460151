import { call, put, takeLeading } from 'redux-saga/effects';
import { getProfiles } from '../../services/profiles';
import ActionHelper from '../../utils/action-helper';
import PROFILE_ACTION_TYPES from '../actions/PROFILE_ACTION_TYPES';

/**
 * Get Profile from backend
 */
export function* getProfileList({ payload: input }) {
  try {
    const response = yield call(getProfiles, input);
    yield put(ActionHelper.fire(PROFILE_ACTION_TYPES.LIST_SUCCESS, response));
  } catch (error) {
    console.log('Error fetching profiles:', error);
    yield put(
      ActionHelper.fire(PROFILE_ACTION_TYPES.LIST_FAILURE, error.toString())
    );
  }
}

export default function* cityList() {
  yield takeLeading(PROFILE_ACTION_TYPES.LIST_REQUEST, getProfileList);
}
