import { all, fork } from 'redux-saga/effects';

import cityList from './cityList';
import provinceList from './provinceList';
import countryList from './countryList';

import educationList from './educationList';
import methodologyList from './methodologyList';
import languageList from './languageList';
import technologyList from './technologyList';

import offerAdd from './offerAdd';

import certificationList from './certificationList';
import profileList from './profileList';

import professionalAdd from './professionalAdd';

export default function* root() {
  yield all([
    fork(countryList),
    fork(technologyList),
    fork(provinceList),
    fork(cityList),
    fork(educationList),
    fork(methodologyList),
    fork(languageList),
    fork(offerAdd),
    fork(certificationList),
    fork(profileList),
    fork(professionalAdd),
  ]);
}
