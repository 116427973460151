import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { CircularProgress } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import FormInput from './formInput';

const filter = createFilterOptions();

// eslint-disable-next-line
const FormAutocomplete = forwardRef((props, ref) => {
  const {
    name,
    label,

    // used props from Mui Autocomplete
    options,
    loading,
    getOptionLabel,
    filterOptions,
    TextFieldProps,
    ...otherProps
  } = props;
  const [field, meta] = useField(props);

  const handleGetOptionLabel = (option) => {
    if (getOptionLabel) {
      return getOptionLabel(option);
    }
    if (typeof option === 'string') {
      return option;
    }
    return option.name;
  };

  const handleFilterOptions = (options, params) => {
    let filtered = filter(options, params);
    if (filterOptions) {
      filtered = filterOptions(options, params);
    }

    return filtered;
  };

  return (
    <Autocomplete
      {...field}
      {...otherProps}
      ref={ref}
      id={name}
      options={options}
      loading={loading}
      getOptionLabel={handleGetOptionLabel}
      filterOptions={handleFilterOptions}
      popupIcon={<KeyboardArrowDownIcon />}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderOption={(props, option) => {
        return <li {...props}>{handleGetOptionLabel(option)}</li>;
      }}
      renderInput={(params) => (
        <FormInput
          {...params}
          {...TextFieldProps}
          error={meta.touched && Boolean(meta.error)}
          helperText={meta.touched && meta.error}
          label={label}
          InputProps={{
            ...params.InputProps,
            ...TextFieldProps?.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
});

FormAutocomplete.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  // used props from Mui Autocomplete
  options: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  getOptionLabel: PropTypes.func,
  filterOptions: PropTypes.func,
  TextFieldProps: PropTypes.object,
  // other properties are the same as MUI Autocomplete
};

export default FormAutocomplete;
