import { LOCALES } from '../locales';

const locale = {
  [LOCALES.ES.value]: {
    home: 'Inicio',
    aboutUs: 'Sobre Nosotros',
    businessTalent: 'Área Empresarial',
    professionalsJob: 'Área Profesionales',
    contactUs: 'Contáctanos',

    lookingFor: 'Buscando',
    iTTalents: 'Talentos TI',
    forYourBusiness: 'para tu negocio',
    weProvideTalent: 'Proporcionamos Talento TI cuando más lo necesitas',
    findExcellentProfessionals:
      'Encontrar excelentes profesionales TI es la clave para equipos exitosos. Lo sabemos y lo tenemos para ti.',
    tellUs: 'Cuéntanos',

    softwareEngineersInSpain: 'Ingenieros de Software en España',
    annualDemandForTechnicians: 'Demanda Anual de Técnicos en Todo el Mundo',
    iTJobs: 'Empleos de TI para 2024 a Nivel Global',

    findingExcellentProfessionals:
      'Encontrar excelentes profesionales TI es clave para construir',
    greatTeams: 'grandes equipos',
    enterOurAreaForCompanies: 'Ingresa a nuestra área para empresas',
    ourDifferentiatingElement:
      'Nuestro elemento diferenciador son nuestras tarifas competitivas y la excelencia técnica de nuestros profesionales TI. Descubre más sobre nuestra oferta de servicios en nuestra área para empresas.',
    ourDifferentiatingElement2:
      'Nuestro equipo está formado por expertos en el sector TI. Garantizamos la presentación de los mejores perfiles mediante pruebas técnicas exhaustivas realizadas por nuestro personal técnico.',
    searchAndSelection: 'Búsqueda y Selección de Talento TI',
    weValidateInOurTeam:
      'Ofrecemos un servicio especializado en la búsqueda y selección de talento TI. Validamos a los candidatos de nuestro equipo y de nuestra pool de profesionales para asegurarnos de que cumplen con tus requerimientos.',
    knowledgeToTheBest: 'Conocimiento de los Mejores Talentos',
    connectProfessionals:
      'Conectamos a profesionales con empresas que valoran y potencian sus habilidades. Nuestra experiencia nos permite formar equipos sólidos con el talento y la seniority necesarios para alcanzar resultados de calidad.',
    appropriateFollow:
      'Nuestro equipo está formado por expertos en el sector TI. Garantizamos la presentación de los mejores perfiles mediante pruebas técnicas exhaustivas realizadas por nuestro personal técnico.',
    weAreAConsolidatedCompany:
      'Somos una empresa consolidada con más de 15 años de experiencia en el sector Tecnológico y de Reclutamiento.',
    readMore: 'Leer más',

    youTellUsWhatYouNeed: 'Dinos lo que necesitas',
    youTellUsWhatYouNeedTitle:
      'Soluciones Eficientes para la Selección de Talento TI',
    youTellUsWhatYouNeedSubtitle:
      'Combinamos experiencia avanzada y tecnología avanzada para optimizar la selección de talento TI. Desde identificar tus necesidades hasta implementar soluciones de inteligencia artificial, ofrecemos un servicio completo para conectarte con los mejores profesionales del sector.',
    talentSelectionProcessQuick:
      'Hacemos que tu proceso de selección de talento TI sea rápido y sencillo. Cuéntanos lo que necesitas y te conectaremos con los candidatos ideales, ahorrándote tiempo y dinero.',
    weOptimizeYourTime: 'Optimizamos tu tiempo',
    yourTimeIsMoney:
      'Tu tiempo es dinero. En Connecting Tech People, nos encargamos de la parte compleja de la búsqueda de talento. Seleccionamos, filtramos y evaluamos candidatos sin costo para ti.',
    codeLovers: 'Somos Amantes del Código',
    ourPassionForCode:
      'En Connecting Tech People, nuestra pasión por el código nos impulsa a encontrar a los mejores profesionales de TI. Gestionamos los aspectos más desafiantes de la búsqueda de talento, incluyendo la selección, el filtrado y las pruebas técnicas, todo sin costo para ti.',
    weSimplifyProcess: 'Simplificamos el Proceso',
    simplifyingTheTalentAcquisitionProcess:
      'Simplificar el proceso de adquisición de talento es nuestra prioridad. En Connecting Tech People, nos encargamos de la selección detallada, el filtrado y las pruebas técnicas de los candidatos, facilitándote encontrar el ajuste perfecto sin costo.',
    weProvideAIProcess: 'Ofrecemos Procesos de IA',
    providingAnAIProcess:
      'Nuestra experiencia en procesos de IA significa que implementamos y gestionamos sistemas avanzados de inteligencia artificial para realizar tareas específicas o resolver problemas complejos, mejorando la eficiencia y efectividad en tus proyectos.',

    doYouWantToBoostYourCareer: '¿Quieres impulsar tu carrera?',
    joinUs:
      'Únete a nosotros y encuentra el proyecto perfecto para ti. Valoramos tu talento y te guiamos hacia el éxito profesional.',
    weAreTheAgency: 'Somos la agencia que necesitas',
    jobPool: 'Bolsa de trabajo TI',
    weManage:
      'Gestionamos la demanda de profesionales de TI de varias empresas del sector, ofreciendo numerosas oportunidades donde tus habilidades pueden brillar. Nuestra extensa red garantiza que encuentres el ajuste perfecto para tu experiencia y aspiraciones profesionales.',
    weAccompanyYou: 'Te Acompañamos',
    weHelpYou:
      'Te apoyamos en definir y mejorar tu perfil profesional. Nuestro equipo ofrece orientación experta para refinar tu currículum, redactar cartas de presentación convincentes y prepararte para entrevistas de trabajo exitosas, asegurando que destaques ante los empleadores potenciales.',
    orientation: 'Orientación Profesional',
    ourOrientationServices:
      'Nuestros servicios de orientación están diseñados para ayudarte a navegar tu camino profesional de manera efectiva. Ofrecemos asesoramiento personalizado para mejorar tu currículum, carta de presentación y habilidades de entrevista, empoderándote para alcanzar tus objetivos profesionales con confianza.',

    businessArea: 'Área de negocios',
    weAreYourAlly:
      'Somos tu aliado en la búsqueda de talentos TI para tu equipo. Nuestra metodología es simple y eficiente, descubre las ventajas de trabajar con nosotros.',
    professionalsArea: 'Área de Profesionales',
    weBoostYour:
      'Impulsamos tu carrera conectándote con las mejores oportunidades en el sector TI. Únete a nuestra red de profesionales y lleva tu carrera al siguiente nivel.',
    joinUsProfessionalArea: 'Únete',
    weAreCommitted:
      'Estamos comprometidos a ayudar a los profesionales de TI a avanzar en sus carreras. Nuestra metodología simplificada te conecta con las mejores empresas que buscan tus habilidades. Descubre los beneficios de asociarte con nosotros y da el siguiente paso en tu carrera profesional.',
    anyTimeSupport: 'Apoyo y ayuda en cualquier momento',
    // FOOTER
    connectWithUs: 'Conéctate con nosotros',
    ourYearsOfExperience:
      'Nuestros años de experiencia y la satisfacción de nuestros clientes nos avalan.',
    fullName: 'Nombre',
    lastName: 'Apellido',
    phoneNumber: 'Número de teléfono',
    email: 'Correo electrónico',
    message: 'Mensaje',
    iAgreeProvide:
      'Acepto proporcionar mis datos para que puedan gestionar mi solicitud de información.',
    privacyPolicy: 'Política de privacidad',
    submit: 'Enviar',
    responseContact: 'Te contactaremos en breve',
    rejectContact: 'Intenta nuevamente por favor',
    sent: 'Enviado',
    tryAgain: 'Intenta otra vez por favor',

    weBringTheYears:
      'Traemos los años, la experiencia global y la resistencia para guiar a nuestros clientes a través de realidades nuevas y a menudo disruptivas.',
    usefullLinks: 'Enlaces útiles',
    termsAccept: 'Debes aceptar los términos y condiciones.',
    professionalsWeAreTitle: 'Somos la agencia que necesitas',
    professionalsWeAreSubtitle:
      'Gracias a nuestro contacto directo con las empresas, conocemos los requisitos más demandados para cada puesto de trabajo.',
    professionalsWeAreEnterTitle: 'Entra a nuestra área comunitaria',
    professionalsWeAreEnterText:
      'Nuestro elemento diferenciador son nuestras excelentes tarifas y la excelencia técnica de nuestros profesionales. Le invitamos a conocer más sobre nuestra oferta de servicios.',
    professionalsWeAreSearchTitle: 'Búsqueda y Selección',
    professionalsWeAreSearchText:
      'Validamos en nuestro equipo y en nuestro pool de profesionales si alguno cumple con tus requisitos o nos dirigimos a la búsqueda y selección del candidato perfecto.',
    professionalsWeAreKnowledgeTitle: 'Conocimiento a prueba',
    professionalsWeAreKnowledgeText:
      'Un seguimiento adecuado puede ayudarnos a identificar malentendidos y resolver dudas, o realizar más evaluaciones y ajustar tratamientos.',
    termsCondition: 'Términos y condiciones',
    allRightsReserved: 'Todos los derechos reservados',
    invalidEmail: 'Email inválido',
    invalidPhone: 'Teléfono inválido',
    invalidMessage: 'Déjanos tu mensaje',
    invalidRecaptcha: 'Por favor, verifica que no eres un robot',
    required: 'Requerido',
    getInTouch: 'Ponerme en contacto',

    professionalFormTitle: '¿Estás buscando el trabajo perfecto?',
    professionalFormSubtitle:
      'En Connecting Tech People trabajamos intensamente y meticulosamente en la contratación de talentos para conectar a nuestros clientes con su candidato ideal.',
    professional: 'Profesional',
    profile: 'Perfil',
    extras: 'Extras',
    jobDescription: 'Descripción del Trabajo',
    state: 'Estado',
    features: 'Características',
    linkedin: 'Linkedin',
    profiles: 'Pérfiles',
    certifications: 'Certificaciones',
    experienceSince: 'Año de inicio',
    professionalLevel: 'Nivel Profesional',
    workingDay: 'Jornada',
    availability: 'Disponibilidad',
    salary: 'Salario',

    // policy
    politics:
      'Políticas de Privacidad de LinkedIn Sidebar de Connecting Tech People',
    infoGeneral: 'Información General',
    textGeneral:
      'LinkedIn Sidebar está diseñada para mejorar la productividad de los profesionales permitiendo el acceso rápido a perfiles de LinkedIn directamente desde un panel lateral en el navegador.',
    useData: 'Uso de Datos',
    textData:
      'No recopilamos ni almacenamos información personal de los usuarios. La extensión ejecuta scripts localmente en su dispositivo y no transmite datos personales fuera de él.',
    allowsExtension: 'Permisos de la Extensión',
    activeTab: ' Utilizado para interactuar con la pestaña activa.',
    contextMenus: 'Permite añadir elementos al menú contextual de Chrome.',
    storage: 'Usado para guardar las preferencias del usuario.',
    tabs: 'Necesario para la gestión de las pestañas durante la interacción con la extensión.',
    sidePanel: 'Permite mostrar el contenido de LinkedIn en un panel lateral.',

    // 404
    pageNotFound: 'Página no encontrada',
    soSorry: 'Lo sentimos 😔 esta página no existe',
    goHome: 'Ir a Inicio',

    // Cookies

    titleCookies: 'POLÍTICA DE COOKIES',
    textCookies1:
      'Esta política de cookies es parte integrante del Aviso Legal y la Política de Protección de Datos de',
    textCookies2:
      '(en adelante el sitio web). El acceso y la navegación en el sitio web, o el uso de los servicios del mismo, implican la aceptación de los términos y condiciones recogidos en el Aviso Legal y en la Política de Protección de Datos.',
    textCookies3: 'Con el fin de facilitar su navegación por el sitio web, ',
    textCookies4: '(en adelante, el prestador) con domicilio social en ',
    textCookies5:
      'con CIF B01638196, le comunica que utiliza cookies u otros archivos de funcionalidad similar (en adelante, las cookies).',
    textCookies6:
      'En todo caso, le informamos de que el prestador es el responsable de las cookies y del tratamiento de los datos obtenidos a través de cookies propias y de terceros, decidiendo sobre la finalidad, contenido y uso del tratamiento de la información recabada.',
    subtitleCookies1: '¿Qué es una cookie?',
    textCookies7:
      'Las cookies son archivos que contienen pequeñas cantidades de información que se descargan en el dispositivo del usuario cuando visita una página web. Su finalidad principal es reconocer al usuario cada vez que accede al sitio web permitiendo, además, mejorar la calidad y ofrecer un mejor uso del sitio web.',
    textCookies8:
      'Las cookies son esenciales para el funcionamiento de Internet; no pueden dañar el equipo/dispositivo del usuario y, si se encuentran activadas en la configuración de su navegador, ayudan a identificar y resolver posibles errores de funcionamiento del sitio web. Uso de Cookies por parte del prestador',
    textCookies9:
      'Mediante el acceso al Sitio Web, acepta de manera expresa la utilización de este tipo de Cookies en sus dispositivos. Si desactiva las Cookies, puede que su navegación por el Sitio Web no sea óptima y algunas de las utilidades del Sitio Web no funcionen correctamente.',
    textCookies10:
      'Concretamente, el prestador está utilizando las Cookies para las finalidades que a continuación se exponen. Si en un futuro el prestador utilizase otras con el propósito de otorgar más y mejores servicios, se informará al usuario de ello.',
    textCookies11:
      'El Sitio Web también utiliza Cookies de rendimiento de Google Inc. Estas cookies recopilan información anónima sobre el modo en que los visitantes utilizan un sitio web, por ejemplo, qué páginas consultan los visitantes con más frecuencia, entre otras.',
    textCookies12:
      'Estas cookies no recopilan información identificativa del visitante. Toda la información que recopilan las cookies se junta y, por lo tanto, es anónima. Únicamente se utilizan para mejorar el funcionamiento de un sitio Web. Para más información respecto al funcionamiento e inhabilitación de las cookies puede visitar las páginas web del Centro de privacidad de Google y del Complemento de inhabilitación de Google Analytics. Sobre las posibles cookies implementadas en esta página web, referidas a Google Analytics, se encuentran:',
    textCookies13:
      'Cookies Nombre Propósito Información Google Analytics __utma __utmb __utmc __utmz Recopilan información anónima sobre la navegación por la web para conocer el origen de las visitas y otros datos estadísticos. Google Analytics Cookie Usage on Websites Connecting Tech People no puede controlar ni se hace responsable del contenido y veracidad de los términos y condiciones y las políticas de privacidad de WordPress.com o de Google Analytics. Son ambos quienes determinan la finalidad del tratamiento y uso de la información captada por los mismos, en todo momento, así como el funcionamiento y duración de las cookies, y que, conforme a la información proporcionada por ambas, el usuario puede evitar la captación de esa información, rechazando la instalación de cookies mediante la configuración por su parte de su navegador.',
    textCookies14:
      'El Sitio Web podría llegar a emplear cookies necesarias para recordar durante su vigencia las preferencias de navegación del usuario (e.g. reconocer si el usuario emplea un dispositivo móvil o un ordenador, con la finalidad de la personalización de la interfaz del usuario). La herramienta de aviso sobre uso de cookies instalada en la página web, puede emplear una cookie de sesión cuya finalidad es recordar las preferencias del usuario respecto de la información facilitada sobre este mecanismo, para cumplir con el deber de información sobre uso de cookies.',
    textCookies15:
      'Al cargar el Sitio Web y acceder al mismo, puede producirse “almacenamiento en bases de datos” y “almacenamiento local” para facilitar su navegación de forma más rápida, ágil y sencilla.',
    textCookies16:
      'Por último, podrían instalarse cookies de terceros en el caso de que el usuario utilice las herramientas para compartir alguno de los contenidos de la página web en las diferentes redes sociales. Dichas cookies son instaladas por las propias redes sociales en las que se comparte el contenido. Para conocer más sobre ellas, se recomienda visitar las páginas de dichas herramientas sociales.',
    subtitleCookies2: 'Configuración del usuario para evitar Cookies',
    textCookies17:
      'En cumplimiento de la normativa legal vigente, ponemos a su disposición la información que le permita configurar su navegador/navegadores de Internet para mantener su privacidad y seguridad en relación con las Cookies. Por ello, le facilitamos la información y enlaces a los sitios de soporte oficiales de los principales navegadores para que pueda decidir si desea o no aceptar el uso de Cookies.',
    textCookies18:
      'Así, puede bloquear las Cookies a través de las herramientas de configuración del navegador, o bien, puede configurar su navegador para que le avise cuando un servidor quiera guardar una Cookie:',
    textCookies19:
      'Se entenderá que el usuario acepta la utilización de las cookies si continúa navegando por esta página web sin proceder previamente a la desactivación de las mismas.',

    // Cookie Consent
    cookieConsentTitle: 'Este sitio web utiliza cookies.',
    cookieConsentDescription:
      'Utilizamos cookies para mejorar el funcionamiento del sitio y analizar nuestro tráfico. Solo usaremos cookies si nos lo permites haciendo clic en "Aceptar cookies". También puedes elegir qué cookies deseas permitir.',
    cookieConsentLearnMore: 'Más información sobre nuestras cookies',
    cookieConsentAccept: 'Aceptar cookies',
    cookieConsentDecline: 'Rechazar',
    cookieConsentPersonalize: 'Personalizar',
    cookieConsentSave: 'Guardar',

    // Cookie types
    cookieTypeEssential: 'Esenciales',
    cookieTypeEssentialDescription:
      'Las cookies esenciales son necesarias para el correcto funcionamiento del sitio. El sitio no puede funcionar correctamente sin ellas.',
    cookieTypeAnalytics: 'Google Analytics',
    cookieTypeAnalyticsDescription:
      'Google Analytics es una herramienta estadística de Google que permite medir la audiencia del sitio web.',
    cookieTypeTagManager: 'Google Tag Manager',
    cookieTypeTagManagerDescription:
      'Google Tag Manager es un sistema de gestión de etiquetas que le permite actualizar rápida y fácilmente etiquetas y fragmentos de código en su sitio web.',

    // About Us
    aboutUsTitle1: 'Aportamos Talento cuando',
    aboutUsTitle2: 'más lo necesitas',
    aboutUsSubtitle:
      'Encontrar excelentes profesionales es clave para formar grandes equipos',
    aboutUsCultureTitle: 'Nuestra cultura',
    aboutUsCultureSubtitle:
      'Somos un único equipo compuesto por nuestros clientes, asociados y nosotros mismos.',
    aboutUsCultureCard1Title: 'Nuestra misión',
    aboutUsCultureCard1Content:
      'Conectar profesionales expertos con las mejores empresas del sector TI. Nos enfocamos en proporcionar talento altamente calificado que impulsa la innovación y el crecimiento empresarial.',
    aboutUsCultureCard2Title: 'Nuestra visión',
    aboutUsCultureCard2Content:
      'Ser el proveedor líder en la industria TI, facilitando la conexión entre profesionales y empresas. Aspiramos a ser la primera opción para las organizaciones que buscan excelencia técnica y experiencia comprobada.',
    aboutUsCultureCard3Title: 'Nuestros valores',
    aboutUsCultureCard3Content:
      'Compromiso, responsabilidad, integridad y transparencia. Fomentamos un entorno de trabajo ético y colaborativo que promueve el desarrollo profesional y la satisfacción del cliente.',
    aboutUsHistoryTitle: 'Historia de nuestra agencia',
    aboutUsHistoryYears: '+15 años',
    aboutUsHistoryExperience: 'Experiencia empresarial',
    aboutUsHistoryContent:
      'Hacemos que tu proceso de selección de talento sea rápido, fácil y sencillo. Solo nos dices lo que necesitas y te conectaremos con los candidatos que se ajusten a tus necesidades, ahorrando así tiempo y dinero.',
    aboutUsHistory1Title: 'Experiencia en el sector tecnológico',
    aboutUsHistory1Paragraph:
      'Somos una empresa consolidada con más de 15 años de experiencia. Durante todo este tiempo, hemos adquirido experiencia en el sector tecnológico y de reclutamiento, lo que nos permite construir, en tiempo récord, equipos sólidos, con gran talento y la seniority necesaria para lograr resultados de calidad.',
    aboutUsHistory2Title: 'También somos expertos en el sector.',
    aboutUsHistory2Paragraph1:
      'En Connecting Tech People, además de ser especialistas en servicios de reclutamiento y selección, también somos expertos en el sector.',
    aboutUsHistory2Paragraph2:
      'Entendemos la industria y sabemos que lo que hacemos tendrá un gran impacto en el mundo empresarial, por eso contamos con un equipo altamente calificado con los profesionales necesarios para llevar a cabo nuestros procesos de manera ágil y efectiva.',
    aboutUsProfessionalsTitle: 'Puedes encontrar a nuestros profesionales en',
    testimonial: 'Testimonios',
    aboutUsReviewsTitle: 'Comentarios de nuestros clientes',
    aboutUsReviewsContent1:
      'Nuestros clientes confían en nosotros para encontrar los mejores profesionales del sector tecnológico. Aquí compartimos algunas de sus experiencias con Connecting Tech People.',
    aboutUsReviewsContent2:
      'Trabajar con Connecting Tech People ha sido una experiencia transformadora para nuestra empresa. Su capacidad para encontrar talento altamente cualificado y ajustado a nuestras necesidades ha mejorado significativamente nuestra productividad y eficiencia. Recomiendo altamente sus servicios.',
    aboutUsReviewsContent3:
      'Connecting Tech People no solo nos ha proporcionado excelentes profesionales, sino que también ha demostrado un profundo conocimiento del sector tecnológico. Gracias al apoyo de su equipo, hemos podido completar proyectos críticos en tiempo récord. Su compromiso con la calidad es inigualable.',
    aboutUsReviewsContent4:
      'Hemos colaborado con Connecting Tech People en varias ocasiones y siempre han superado nuestras expectativas. Su proceso de selección es riguroso y efectivo, lo que nos asegura que solo trabajamos con los mejores talentos del mercado. Su equipo es profesional y muy accesible.',

    // Business

    businessTalentTitle1: '¿Buscas talentos IT para',
    businessTalentTitle2: 'tu equipo?',
    businessTalentSubtitle:
      'Proporcionamos el mejor talento IT cuando más lo necesitas',
    fillTheForm: 'Rellena el formulario',

    businessTalentWhyWorkTitle: 'Por qué trabajar con nosotros',
    businessTalentWhyWorkContent:
      'Hacemos que tu proceso de selección de talento IT sea rápido, fácil y eficiente. Solo dinos lo que necesitas y te conectaremos con candidatos que cumplan con tus requisitos, ahorrándote tiempo y dinero.',
    businessTalentWhyWork1Paragraph1:
      'En Connecting Tech People, alcanzamos los perfiles tecnológicos más demandados y los estándares profesionales más altos. Estos profesionales pueden formar parte de tu equipo a largo plazo o por un período específico que se adapte mejor a tus necesidades. Nuestro servicio es especialmente útil para empresas que prefieren evitar la contratación directa.',
    businessTalentWhyWork1Paragraph2:
      'Fomentamos alianzas estratégicas con nuestros clientes, generamos oportunidades de desarrollo profesional para nuestro equipo y contribuimos positivamente a nuestra comunidad.',
    businessTalentWhyWork1Paragraph3:
      'Formamos parte de este mercado laboral, por lo que conocemos de primera mano las últimas tendencias y necesidades del sector, y nos adaptamos a ellas.',
    businessTalentWhyWork2Title: 'Somos expertos en tecnología',
    businessTalentWhyWork2Paragraph1:
      'Nuestra plataforma de emparejamiento con IA, desarrollada internamente, utiliza algoritmos avanzados y aprendizaje automático para mejorar la calidad de las contrataciones. Esta tecnología nos permite identificar y conectar a los candidatos más cualificados con las ofertas de trabajo adecuadas, optimizando el proceso de selección.',
    businessTalentWhyWork2Paragraph2:
      'El uso de nuestra plataforma de IA no solo beneficia a nuestros clientes al proporcionarles acceso a talento altamente calificado, sino que también mejora la experiencia de los profesionales al emparejarlos con oportunidades que se ajustan perfectamente a sus habilidades y aspiraciones.',
    businessTalentWhyWork2Paragraph3:
      'Para nosotros, la implementación de esta plataforma de IA significa una mayor eficiencia en el reclutamiento. Automatizamos tareas repetitivas, lo que permite a nuestros reclutadores centrarse en actividades de mayor valor, como entrevistas y la evaluación detallada de candidatos, asegurando así un proceso de selección más efectivo y personalizado.',
    businessTalentBrandsTitle:
      'Tecnologías que usamos en Connecting Tech People',

    // Business / Candidate
    businessCandidateTitle: '¿Buscando al candidato perfecto?',
    businessCandidateSubtitle:
      'En Connecting Tech People trabajamos intensa y meticulosamente en el reclutamiento de talentos para conectar a nuestros clientes con su candidato ideal.',
    back: 'Volver',
    next: 'Siguiente',
    pending: 'Pendiente',
    inProgress: 'En Progreso',
    completed: 'Completado',
    step: 'Paso',
    company: 'Empresa',
    offer: 'Oferta',
    requirements: 'Requerimientos',
    enter: 'Ingrese',
    companyName: 'Nombre de la Compañia',
    contactPerson: 'Persona de Contacto',
    offerTitle: 'Titulo de Oferta',
    offerLink: 'Url de Oferta',
    description: 'Descripción',
    comments: 'Comentarios',
    vacancies: 'Vacantes',
    modality: 'Modalidad',
    contractType: 'Tipo de Contrato',
    education: 'Nivel Académico',
    experienceYears: 'Años de Experiencia',
    mainTechnologies: 'Tecnologías Imprescindibles',
    otherTechnologies: 'Tecnologías Opcionales/Deseables',
    mainMethodologies: 'Metodologías Imprescindibles',
    otherMethodologies: 'Metodologías Opcionales/Deseables',
    mainLanguage: 'Idioma Principal',
    secondaryLanguage: 'Idioma Secundario',
    country: 'País',
    province: 'Provincia',
    city: 'Ciudad',
    limitDate: 'Fecha Limite',
    payFrom: 'Salario Min',
    payTo: 'Salario Máx',
    responsibilities: 'Responsabilidades',

    // Business / Recommended Section
    businessRecommendationTitle: 'Por qué elegir nuestros servicios',
    businessRecommendationSubtitle:
      'Descubre los beneficios de trabajar con nosotros',

    businessRecommendationSaveTimeTitle: 'Ahorra tiempo',
    businessRecommendationSaveTime:
      'La búsqueda y selección de candidatos lleva tiempo; nosotros lo hacemos por ti, sin compromiso.',

    businessRecommendationSafeSelectionTitle: 'Selección segura',
    businessRecommendationSafeSelection:
      'En lugar de revisar innumerables currículums, te enviaremos solo los candidatos que se ajusten a tus necesidades.',

    businessRecommendationTechnicalValidationTitle: 'Validación técnica',
    businessRecommendationTechnicalValidation:
      'Nos aseguramos de que los candidatos cumplan con el nivel de habilidad requerido a través de la validación técnica de nuestro equipo.',

    businessRecommendationInterestedCandidatesTitle: 'Candidatos interesados',
    businessRecommendationInterestedCandidates:
      'Presentamos candidatos a tu empresa. Solo entrevistas a aquellos realmente interesados. ¡Tu tiempo es valioso!',

    businessRecommendationSimplifyProcessesTitle: 'Simplifica procesos',
    businessRecommendationSimplifyProcesses:
      'Recibirás una única factura al final del mes por los servicios prestados. Nosotros gestionamos los pagos a los candidatos.',

    businessRecommendationWithoutPermanenceTitle: 'Sin permanencia',
    businessRecommendationWithoutPermanence:
      'Paga solo por el tiempo que necesites el recurso, sin compromisos a largo plazo que aumenten tus gastos.',

    businessRecommendationPayForServiceTitle: 'Paga por servicio',
    businessRecommendationPayForService:
      'Evita el compromiso de una contratación a largo plazo. Paga al final del mes por los días trabajados.',

    businessRecommendationCompetitiveRatesTitle: 'Tarifas competitivas',
    businessRecommendationCompetitiveRates:
      'Nuestras tarifas son altamente competitivas, lo que lleva a un ahorro significativo para nuestros clientes.',

    // Business / How Work

    businessHowWorkTitle: 'Cómo trabajamos',
    businessHowWorkSubtitle:
      'En Connecting Tech People trabajamos intensamente y meticulosamente en la selección de talentos para conectar a nuestros clientes con su candidato ideal.',

    businessHowWorkStep1Title: 'Cuéntanos tus requisitos',
    businessHowWorkStep1Content:
      'Rellena el formulario web con tus requisitos y te llamaremos para aclarar detalles. Nuestro objetivo es entender tus necesidades específicas para ofrecerte los mejores candidatos.',

    businessHowWorkStep2Title: 'Búsqueda y selección activa',
    businessHowWorkStep2Content:
      'Nuestro equipo de expertos evaluará los candidatos seleccionando los más adecuados. Nos apoyamos en IA para asegurar que cumplen con tus requisitos, los incluimos en nuestro equipo y te los presentamos.',

    businessHowWorkStep3Title: 'Prueba técnica',
    businessHowWorkStep3Content:
      'Los candidatos seleccionados pasarán por una prueba técnica donde demostrarán sus conocimientos. Esta evaluación garantiza que los candidatos tienen las habilidades necesarias para el puesto.',

    businessHowWorkStep4Title: 'Presentación de candidatos',
    businessHowWorkStep4Content:
      'Te enviaremos los datos relevantes del candidato seleccionado y las condiciones de la oferta que se ajustan a tus necesidades. Nuestro proceso asegura que solo recibas candidatos que cumplen con tus expectativas.',

    businessHowWorkStep5Title: 'Entrevista con el profesional',
    businessHowWorkStep5Content:
      'Organizamos una reunión para que puedas conocer al candidato personalmente y evaluar su idoneidad. Este paso es crucial para asegurar que el candidato se ajuste a tu cultura empresarial.',

    businessHowWorkStep6Title: 'Contratación y arranque',
    businessHowWorkStep6Content:
      'Una vez que hayas aprobado al candidato, procederemos a la firma del contrato y nos encargaremos de todos los preparativos necesarios para que el profesional se incorpore a tu equipo de manera eficiente y sin contratiempos desde el primer día.',

    // Profesionales
    professionalJobTitle1: 'Impulsa tu carrera en TI',
    professionalJobTitle2: 'con Connecting Tech People',
    professionalJobSubtitle:
      'Únete a nuestra red de talento tecnológico de élite. Conectamos profesionales de TI cualificados con proyectos innovadores y empresas líderes del sector. Eleva tu carrera en desarrollo de software, ciencia de datos, ciberseguridad y más.',

    professionalJobConnectTitle: 'Conéctate con oportunidades tecnológicas',
    professionalJobConnectContent:
      'Nuestro sistema de emparejamiento de talento impulsado por IA te conecta rápidamente con roles tecnológicos ideales. Comparte tus habilidades y preferencias, y encontraremos oportunidades que se alineen con tu experiencia y objetivos profesionales.',
    professionalJobConnect1Paragraph1:
      'Como agencia líder de talento en TI, nos especializamos en colocar profesionales cualificados en entornos donde sus habilidades técnicas y competencias blandas son valoradas y pueden prosperar.',
    professionalJobConnect1Paragraph2:
      'Optimizamos tu búsqueda de empleo involucrándote en oportunidades cuidadosamente seleccionadas que coinciden con tu perfil profesional, aumentando tus posibilidades de conseguir el puesto tecnológico perfecto.',
    professionalJobConnect1Paragraph3:
      'Nuestro innovador sistema de gestión de IA, desarrollado internamente para profesionales de TI y clientes, ofrece ventajas significativas. Procesa eficientemente un alto volumen de ofertas de trabajo y perfiles de candidatos, permitiéndonos proporcionar respuestas más rápidas, realizar entrevistas personales más profundas y minimizar las tareas administrativas.',

    // Business / How Work

    professionalJobHowWorkTitle: 'Cómo trabajamos',
    professionalJobHowWorkSubtitle:
      'En Connecting Tech People trabajamos intensamente y meticulosamente en la selección de talentos para conectar a nuestros clientes con su candidato ideal.',

    professionalJobHowWorkStep1Title: 'Cuéntanos sobre ti',
    professionalJobHowWorkStep1Content:
      'Completa nuestro formulario en línea para conocerte mejor. Te llamaremos para aclarar detalles y entender tus objetivos profesionales, lo que buscas en un proyecto y tus necesidades. ¡Queremos encontrar la oportunidad perfecta para ti!',

    professionalJobHowWorkStep2Title: 'Conozcamos tu perfil',
    professionalJobHowWorkStep2Content:
      'Organizaremos una reunión para conocer tus expectativas y asegurarnos de que la oportunidad se alinea con lo que buscas en tu carrera. Queremos que te sientas cómodo y apoyado en cada paso del proceso.',

    professionalJobHowWorkStep3Title: 'Proceso de búsqueda con IA',
    professionalJobHowWorkStep3Content:
      'Utilizamos IA avanzada para analizar tus habilidades y experiencia. Así, podemos identificar las mejores oportunidades que encajan contigo y presentarte a nuestros clientes como un candidato altamente cualificado.',

    professionalJobHowWorkStep4Title: 'Prueba técnica',
    professionalJobHowWorkStep4Content:
      'Te ofrecemos la oportunidad de mostrar tus habilidades en una prueba técnica. Esta es tu ocasión para destacar y demostrar tu nivel de expertise, lo que ayuda a posicionarte como el candidato ideal para el proyecto.',

    professionalJobHowWorkStep5Title: 'Presentación de oportunidades',
    professionalJobHowWorkStep5Content:
      'Te enviaremos información detallada de las vacantes que mejor se ajusten a tu perfil y expectativas. Nuestro objetivo es presentarte solo proyectos que te motiven y donde puedas desarrollar todo tu potencial.',

    professionalJobHowWorkStep6Title: 'Contratación y arranque',
    professionalJobHowWorkStep6Content:
      'Cuando el proyecto, el equipo y las condiciones cumplan con tus expectativas, firmamos el contrato. Queremos que te sientas seguro y cómodo en cada paso del proceso, brindándote transparencia y apoyo continuo para tu desarrollo profesional.',

    // SEO
    seoTitle: 'Connecting Tech People - Talento Cuando Más Lo Necesitas',
    seoDescription:
      'Connecting Tech People proporciona el mejor talento IT para construir grandes equipos. Con más de 15 años de experiencia, nos especializamos en servicios de reclutamiento y selección en el sector tecnológico.',
    seoKeywords:
      'talento IT, reclutamiento, sector tecnológico, ingenieros de software, profesionales IT, aumento de personal, adquisición de talento',
    seoRobots: 'index, follow',
    seoCanonical: 'https://www.connectingtechpeople.com',
    seoImage: 'https://www.connectingtechpeople.com/images/seo-image.jpg',
    seoAuthor: 'Connecting Tech People',
    seoPublisher: 'Connecting Tech People',

    // Open Graph
    ogImage: 'https://www.connectingtechpeople.com/images/og-image.jpg',

    // Twitter
    twitterCard: 'summary_large_image',
    twitterImage:
      'https://www.connectingtechpeople.com/images/twitter-image.jpg',

    // About Us
    aboutUsSeoTitle: 'Sobre Nosotros - Connecting Tech People',
    aboutUsSeoDescription:
      'Connecting Tech People es una empresa consolidada con más de 15 años de experiencia en el sector Tecnológico y de Reclutamiento. Construimos equipos sólidos con gran talento y seniority para lograr resultados de calidad.',
    aboutUsSeoKeywords:
      'talento IT, reclutamiento, sector tecnológico, ingenieros de software, profesionales IT, aumento de personal, adquisición de talento, profesionales expertos, crecimiento empresarial',
    aboutUsOgUrl: 'https://www.connectingtechpeople.com/aboutUs',

    // SEO Business Area
    businessAreaSeoTitle: 'Area Empresarial - Connecting Tech People',
    businessAreaSeoDescription:
      'Connecting Tech People proporciona el mejor talento IT para construir grandes equipos. Con más de 15 años de experiencia, nos especializamos en servicios de reclutamiento y selección en el sector tecnológico.',
    businessAreaSeoKeywords:
      'talento IT, reclutamiento, sector tecnológico, ingenieros de software, profesionales IT, aumento de personal, adquisición de talento',
    businessAreaOgUrl: 'https://www.connectingtechpeople.com/business',

    // SEO Professionals Area
    professionalsAreaSeoTitle: 'Professionals Area - Connecting Tech People',
    professionalsAreaSeoDescription:
      'Connecting Tech People ofrece oportunidades de carrera emocionantes para profesionales IT. Con nuestra extensa red y experiencia en la industria, conectamos talento de vanguardia con empresas líderes en el sector tecnológico. Únete a nosotros para llevar tu carrera al siguiente nivel.',
    professionalsAreaSeoKeywords:
      'IT jobs, technology careers, software engineer positions, IT professional opportunities, career growth, talent placement, job search, IT recruitment',
    professionalsAreaOgUrl:
      'https://www.connectingtechpeople.com/professionals',
    // Contact
    contactTitle1: 'Conecta',
    contactTitle2: 'con nosotros',
    contactSubtitle:
      'Únete a nosotros y te ayudaremos a encontrar el profesional que necesitas para tu empresa. Conecta con nosotros y descubre cómo podemos impulsar tu crecimiento con talento IT de calidad.',

    contactInfo1Title: 'Nuestro Email',
    contactInfo2Title: 'Chat para soporte',
    contactInfo3Title: 'Llámanos',
    contactInfo3Subtitle: 'Lun - Vie de 8:00 a 20:00',

    //PresentationCard
    presentationCardPosition: 'Posición',
    presentationCardAddress: 'Dirección',
    addToContacts: 'Agregar a Contactos',
    commercialDirector: 'Director Comercial',

    // Form Validation Schema
    stringValidation: 'Ingrese su información',
    selectValidation: 'Seleccione una opción',
    numberValidation: 'Solo se permiten numeros',
    minValidation: 'Debe ser mayor o igual que {min, number}',
    maxValidation: 'Debe ser menor o igual que {max, number}',
    textValidation: 'Solo se permiten letras',
    emailValidation: 'Formato de correo inválido',
    phoneValidation: 'Formato de teléfono inválido',
    linkendinValidation: 'Linkedin inválido',
    urlValidation: 'URL inválido',
    payToValidation: 'El Salario Máx. debe ser mayor que el Salario Min.',
    validateNameExists: 'Validar si existe',
    validateNameValidation:
      'Ya está registrado un profesional con estos nombres y apellidos',
    submitSuccessfull: 'Gracias por enviarnos su información',
    submitError: 'Ocurrió un error. Por favor envíe los datos nuevamente',
  },
};

export default locale;
