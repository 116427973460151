export const STATE = Object.freeze({
  NONE: 'NONE',
  FETCHING: 'FETCHING',
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE',
  MANAGED: 'MANAGED',
  NOTIFIED: 'NOTIFIED',
});

export const FORM_TYPES = Object.freeze({
  TEXTFIELD: 'textfield',
  TEXTAREA: 'textarea',
  CHECKBOX: 'checkbox',
  RADIO: 'radio',
  AUTOCOMPLETE: 'autocomplete',
  SELECT: 'select',
  DATEPICKER: 'datepicker',
  RANGE: 'range',
});

export const MODALITY = [
  {
    id: '1',
    name: 'Presencial',
  },
  {
    id: '3',
    name: 'Remoto',
  },
  {
    id: '4',
    name: 'Mixto',
  },
];

export const CONTRACT = [
  {
    id: '1',
    name: 'Indefinido',
  },
  {
    id: '2',
    name: 'Temporal(3meses-6meses)',
  },
  {
    id: '3',
    name: 'Temporal con posibilidad de Extender',
  },
];

export const PROFESSIONAL_LEVELS = [
  {
    id: '1',
    name: 'Junior',
  },
  {
    id: '2',
    name: 'Senior',
  },
  {
    id: '3',
    name: 'Especialista',
  },
];

export const WORKING_DAY = [
  {
    id: '1',
    name: 'Completa',
  },
  {
    id: '2',
    name: 'Media Jornada',
  },
];

export const AVAILABILITY = [
  {
    id: '1',
    name: 'Inmediata',
  },
  {
    id: '2',
    name: '15 días naturales',
  },
  {
    id: '3',
    name: '30 días naturales',
  },
];

export const PHONE_REGEX = /^\+?[1-9]\d{1,14}$/;
export const LINKEDIN_REGEX =
  /^((https?:\/\/)?(www\.)?linkedin\.com\/in\/[a-zA-Z0-9%-]+\/?)$/;
