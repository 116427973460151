import { call, put, takeLeading } from 'redux-saga/effects';
import { getLanguages } from '../../services/languages';
import ActionHelper from '../../utils/action-helper';
import LANGUAGE_ACTION_TYPES from '../actions/LANGUAGE_ACTION_TYPES';

/**
 * Get Languages from backend
 */
export function* getLanguageList() {
  try {
    const response = yield call(getLanguages, {});
    yield put(ActionHelper.fire(LANGUAGE_ACTION_TYPES.LIST_SUCCESS, response));
  } catch (error) {
    console.log('Error fetching languages:', error);
    yield put(
      ActionHelper.fire(LANGUAGE_ACTION_TYPES.LIST_FAILURE, error.toString())
    );
  }
}

export default function* languageList() {
  yield takeLeading(LANGUAGE_ACTION_TYPES.LIST_REQUEST, getLanguageList);
}
