import { call, put, takeLeading } from 'redux-saga/effects';
import { getProvinces } from '../../services/provinces';
import ActionHelper from '../../utils/action-helper';
import PROVINCE_ACTION_TYPES from '../actions/PROVINCE_ACTION_TYPES';

/**
 * Get Province from backend
 */
export function* getProvinceList({ payload: input }) {
  try {
    const response = yield call(getProvinces, input);
    yield put(ActionHelper.fire(PROVINCE_ACTION_TYPES.LIST_SUCCESS, response));
  } catch (error) {
    console.log('Error fetching provinces:', error);
    yield put(
      ActionHelper.fire(PROVINCE_ACTION_TYPES.LIST_FAILURE, error.toString())
    );
  }
}

export default function* provinceList() {
  yield takeLeading(PROVINCE_ACTION_TYPES.LIST_REQUEST, getProvinceList);
}
