import * as React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Stack, Typography } from '@mui/material';

import * as styles from './style.module.scss';

const StepLabel = ({
  text1,
  text2,
  step,
  completed,
  activeStep,
  intl: { formatMessage },
}) => {
  const getStepText = () => {
    if (step === activeStep) {
      return formatMessage({ id: 'inProgress' });
    } else if (completed[step]) {
      return formatMessage({ id: 'completed' });
    } else {
      return formatMessage({ id: 'pending' });
    }
  };
  return (
    <Stack>
      <Typography variant="subtitle2" className={styles.label_text1}>
        {text1}
      </Typography>
      <Typography variant="h5" className={styles.title}>
        {text2}
      </Typography>
      <Typography variant="subtitle1" className={styles.label_text2}>
        {getStepText()}
      </Typography>
    </Stack>
  );
};

StepLabel.propTypes = {
  text1: PropTypes.string,
  text2: PropTypes.string,
  text3: PropTypes.string,
  step: PropTypes.number,
  completed: PropTypes.object,
  activeStep: PropTypes.number,
  intl: PropTypes.object.isRequired,
};
export default injectIntl(StepLabel);
