import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import FormInput from './formInput';

// eslint-disable-next-line
const FormikInput = forwardRef((props, ref) => {
  const [field, meta] = useField(props);

  return (
    <FormInput
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error}
      {...field}
      {...props}
      ref={ref}
    />
  );
});

FormikInput.propTypes = {
  name: PropTypes.string,
};

export default FormikInput;
