import { call, put, takeLatest } from 'redux-saga/effects';

import { createOffers } from '../../services/offers';
import ActionHelper from '../../utils/action-helper';
import OFFER_ACTION_TYPES from '../actions/OFFER_ACTION_TYPES';

/**
 * Create OfferRequest to backend
 */
export function* createOfferEntity({ payload: input }) {
  try {
    const response = yield call(createOffers, input);
    yield put(ActionHelper.fire(OFFER_ACTION_TYPES.ADD_SUCCESS, response));
  } catch (error) {
    console.log('Error posting offerRequest:', error);
    yield put(
      ActionHelper.fire(OFFER_ACTION_TYPES.ADD_FAILURE, error.toString())
    );
  }
}

export default function* offerAdd() {
  yield takeLatest(OFFER_ACTION_TYPES.ADD_REQUEST, createOfferEntity);
}
