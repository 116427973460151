// extracted by mini-css-extract-plugin
export var active_circle = "style-module__active_circle___Y2tFm";
export var background_images = "style-module__background_images___PbVQT";
export var body_background = "#f8f9fa";
export var card = "style-module__card___w2OXR";
export var chip = "style-module__chip___OjHqX";
export var circle = "style-module__circle___p-6p7";
export var completed_circle = "style-module__completed_circle___lN+0o";
export var container = "style-module__container___x8MKc";
export var content = "style-module__content___P2jY1";
export var even_second_color = "#557da1";
export var font_family = "-apple-system,BlinkMacSystemFont,\"Segoe UI\",Roboto,\"Helvetica Neue\",Arial,\"Noto Sans\",sans-serif,\"Apple Color Emoji\",\"Segoe UI Emoji\",\"Segoe UI Symbol\",\"Noto Color Emoji\"";
export var full_img = "style-module__full_img___fmflT";
export var full_imgSmall = "style-module__full_img--small___sctun";
export var gray_21 = "style-module__gray_21___yjeuV";
export var image_container = "style-module__image_container___fTVDV";
export var image_item = "style-module__image_item___9ZUqv";
export var label_text1 = "style-module__label_text1___JEOvN";
export var label_text2 = "style-module__label_text2___Y9du3";
export var lg = "1200px";
export var logo = "style-module__logo___ZQK55";
export var md = "900px";
export var paper_background = "#fff";
export var primary = "style-module__primary___DlsXN";
export var primary_light = "#e0f9f3";
export var secondary = "#868d96";
export var sm = "600px";
export var spacing = "8";
export var step_icon_root = "style-module__step_icon_root___7zuuj";
export var title = "style-module__title___5wzjw";
export var typography_h1 = "style-module__typography_h1___OwRE8";
export var typography_h2 = "style-module__typography_h2___Hk7Ya";
export var typography_h3 = "style-module__typography_h3___1ZRSo";
export var white = "#fff";
export var white_button = "style-module__white_button___V1zP6";
export var xl = "1536px";
export var xxl = "2500px";