import * as React from 'react';
import PropTypes from 'prop-types';
import { AppContextProvider, AppContext } from './context/AppContext';
import { I18nProvider } from './i18n';
import { CssBaseline } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { es } from 'date-fns/locale';
import { Provider } from 'react-redux';
import store from './store';

const locales = { 'en-US': undefined, 'es-ES': es };

const MainContainer = ({ children }) => {
  return (
    <Provider store={store}>
      <AppContextProvider>
        <AppContext.Consumer>
          {(appContext) => (
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={locales[appContext[0]?.language?.value]}
            >
              <I18nProvider locale={appContext[0]?.language}>
                <CssBaseline />
                {children}
              </I18nProvider>
            </LocalizationProvider>
          )}
        </AppContext.Consumer>
      </AppContextProvider>
    </Provider>
  );
};

MainContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default MainContainer;
