import * as React from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Step,
  StepLabel,
  Stepper as MuiStepper,
} from '@mui/material';

import Connector from './connector';
import StepIcon from './stepIcon';
import CustomStepLabel from './stepLabel';
import * as styles from './style.module.scss';

const Stepper = ({ steps, completed, activeStep, onStepChange }) => {
  const handleStepClick = (step) => () => {
    onStepChange(step);
  };

  return (
    <MuiStepper
      alternativeLabel
      nonLinear
      activeStep={activeStep}
      connector={<Connector />}
      sx={{ width: 1 }}
    >
      {steps.map((step, index) => (
        <Step key={index} completed={completed[index]}>
          <StepLabel
            StepIconComponent={StepIcon}
            onClick={handleStepClick(index)}
          >
            {typeof step === 'object' ? (
              <CustomStepLabel
                {...step}
                step={index}
                activeStep={activeStep}
                completed={completed}
              />
            ) : (
              <Typography variant="h5" className={styles.title}>
                {step}
              </Typography>
            )}
          </StepLabel>
        </Step>
      ))}
    </MuiStepper>
  );
};

Stepper.propTypes = {
  steps: PropTypes.array,
  completed: PropTypes.object,
  activeStep: PropTypes.number,
  onStepChange: PropTypes.func,
};
export default Stepper;
