import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import * as styles from './style.module.scss';
import { TextField } from '@mui/material';

// eslint-disable-next-line
const FormInput = forwardRef((props, ref) => {
  return (
    <TextField
      fullWidth
      variant="outlined"
      {...props}
      ref={ref}
      classes={{
        root: styles.Input,
      }}
      InputProps={{
        ...props.InputProps,
        classes: {
          root: styles.Input__formControl,
          input: styles.Input__input,
        },
      }}
    />
  );
});

FormInput.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  id: PropTypes.string,
  InputProps: PropTypes.object,
};

export default FormInput;
