import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import FormInput from './formInput';
import { MenuItem } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

// eslint-disable-next-line
const FormSelect = forwardRef((props, ref) => {
  const [field, meta] = useField(props);

  return (
    <FormInput
      select
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error}
      SelectProps={{ IconComponent: KeyboardArrowDownIcon }}
      {...field}
      {...props}
      ref={ref}
    >
      {props.options.map((option) => (
        <MenuItem key={option.id} value={option.id}>
          {option.name}
        </MenuItem>
      ))}
    </FormInput>
  );
});

FormSelect.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  id: PropTypes.string,
};

export default FormSelect;
