import * as React from 'react';
import PropTypes from 'prop-types';

import * as styles from './style.module.scss';

const StepIcon = (props) => {
  const { active, completed, className } = props;
  let icon = <div className={styles.circle} />;
  if (completed) {
    icon = <div className={styles.active_circle} />;
  }
  if (active) {
    icon = <div className={styles.completed_circle} />;
  }
  return (
    <div className={className || '' + ' ' + styles.step_icon_root}>{icon}</div>
  );
};

StepIcon.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  completed: PropTypes.bool,
  icon: PropTypes.node,
};
export default StepIcon;
