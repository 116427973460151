import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Formik, Form } from 'formik';
import {
  FormikInput,
  FormSelect,
  FormCheckbox,
  FormRadio,
  FormAutocomplete,
  FormDatepicker,
  FormRange,
  Stepper,
  Button,
  Spinner,
} from '@components';
import {
  Grid,
  Stack,
  useTheme,
  useMediaQuery,
  Typography,
  Snackbar,
  Alert,
} from '@mui/material';

import * as styles from './style.module.scss';
import { FORM_TYPES } from '../../utils/consts';
import ReCAPTCHA from 'react-google-recaptcha';

const widths = {
  xs: 1,
  lg: 2,
};

const StepForm = ({
  steps,
  fields,
  schema,
  onSubmit,
  submitted,
  setSubmitted,
  loading,
  snackbar,
  handleCloseSnackbar,
  maxWidth,
  innerClass,
  intl: { formatMessage },
}) => {
  const reCaptchaElement = useRef(null);
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [resetForm, setResetForm] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});
  const theme = useTheme();
  const matchesMdDown = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    if (submitted) {
      if (resetForm) {
        resetForm();
        setActiveStep(0);
        setCompleted({});
        setRecaptchaValue(null);
      }
      setSubmitted(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitted]);

  const getDefaultValues = () => {
    const values = {};

    fields.forEach((field) => {
      if (Array.isArray(field.name)) {
        field.name.forEach((name, i) => {
          values[name] = field.defaultValue[i];
        });
      } else {
        if (field.name.includes('.')) {
          const keys = field.name.split('.');
          if (values[keys[0]]) {
            values[keys[0]][keys[1]] = field.defaultValue;
          } else {
            values[keys[0]] = {
              [keys[1]]: field.defaultValue,
            };
          }
        } else {
          values[field.name] = field.defaultValue;
        }
      }
    });

    return values;
  };

  const handleSubmit = (values, formikProps) => {
    if (!recaptchaValue) {
      formikProps.setFieldError(
        'recaptcha',
        formatMessage({ id: 'invalidRecaptcha' })
      );
      return false;
    }
    onSubmit(values, formikProps);
    setResetForm(formikProps.resetForm);
  };

  function isStepValid(step, errors) {
    const stepFields = fields.filter((field) => field.step === step);
    const stepErrors = {};
    stepFields.forEach((field) => {
      if (errors[field.name]) {
        stepErrors[field.name] = errors[field.name];
      }
    });

    const isValid = Object.keys(stepErrors).length === 0;
    setCompleted({
      ...completed,
      [step]: isValid,
    });
    return isValid;
  }

  const handleStepChange = (errors) => (step) => {
    isStepValid(activeStep, errors);
    setActiveStep(step);
  };

  const handleAddStep =
    ({ errors, setTouched, initialValues }) =>
    () => {
      if (activeStep < steps.length - 1) {
        isStepValid(activeStep, errors);
        setActiveStep((step) => step + 1);
        return;
      }
      const errorKeys = Object.keys(errors);
      const errorFields = fields.filter((field) =>
        errorKeys.includes(field.name)
      );
      const errorStep =
        errorFields.length > 0
          ? errorFields.reduce((min, obj) => (obj.step < min.step ? obj : min))
              .step
          : null;
      if (errorStep !== null) {
        setActiveStep(errorStep);
      }

      const markAllTouched = (values) => {
        return Object.keys(values).reduce((acc, key) => {
          if (typeof values[key] === 'object' && values[key] !== null) {
            acc[key] = markAllTouched(values[key]);
          } else {
            acc[key] = true;
          }
          return acc;
        }, {});
      };
      setTouched(markAllTouched(initialValues));
    };

  const handleBack = (errors) => () => {
    if (activeStep > 0) {
      setActiveStep((step) => step - 1);
      isStepValid(activeStep, errors);
    }
  };

  const getGridProps = (field) => {
    let GridProps = {};
    if (field.fullWidth) {
      GridProps.xs = 12;
    } else if (field?.gridProps) {
      GridProps = field.gridProps;
    } else {
      GridProps = {
        xs: 12,
        lg: widths[maxWidth] >= 2 ? 6 : undefined,
      };
    }
    return GridProps;
  };

  const getComponent = (element, formikProps) => {
    switch (element.type) {
      case FORM_TYPES.TEXTFIELD:
        return (
          <FormikInput
            name={element.name}
            label={element.label}
            id={element.name}
            {...element.ComponentProps}
          />
        );
      case FORM_TYPES.TEXTAREA:
        return (
          <FormikInput
            multiline
            name={element.name}
            label={element.label}
            {...element.ComponentProps}
          />
        );
      case FORM_TYPES.SELECT:
        return (
          <FormSelect
            name={element.name}
            label={element.label}
            options={element.options}
            {...element.ComponentProps}
          />
        );
      case FORM_TYPES.CHECKBOX:
        return (
          <FormCheckbox name={element.name} {...element.ComponentProps}>
            {element.label}
          </FormCheckbox>
        );
      case FORM_TYPES.RADIO:
        return (
          <div>
            <span>{element.label}</span>
            {element.options.map((option) => (
              <FormRadio
                key={option.value}
                name={element.name}
                value={option.value}
                label={option.label}
                {...element.ComponentProps}
                {...option.ComponentProps}
              />
            ))}
          </div>
        );
      case FORM_TYPES.AUTOCOMPLETE:
        return (
          <FormAutocomplete
            name={element.name}
            label={element.label}
            options={element.options}
            loading={element.loading}
            onChange={(event, value) => {
              if (element.onChange) {
                element.onChange(value);
              }
              if (element.resetFieldOnChange) {
                if (Array.isArray(element.resetFieldOnChange)) {
                  element.resetFieldOnChange.forEach((field) => {
                    formikProps.setFieldValue(field, null);
                  });
                } else {
                  formikProps.setFieldValue(element.resetFieldOnChange, null);
                }
              }
              formikProps.setFieldValue(element.name, value);
            }}
            {...element.ComponentProps}
          />
        );
      case FORM_TYPES.DATEPICKER: {
        return (
          <FormDatepicker
            name={element.name}
            label={element.label}
            {...element.ComponentProps}
          />
        );
      }
      case FORM_TYPES.RANGE:
        return (
          <FormRange
            name={element.name}
            label={element.label}
            {...element.ComponentProps}
          />
        );
    }
  };

  const Recaptcha = (formikProps) => (
    <Stack>
      <ReCAPTCHA
        className={styles.container_captcha}
        ref={reCaptchaElement}
        sitekey={process.env.GATSBY_RECAPTCHA_SITE_KEY}
        onChange={(value) => {
          setRecaptchaValue(value);
          formikProps.setFieldError('recaptcha', undefined);
        }}
      />
      {formikProps.errors.recaptcha && (
        <Typography className="text_error">
          {formikProps.errors.recaptcha}
        </Typography>
      )}
    </Stack>
  );

  return (
    <>
      <Formik
        validationSchema={schema}
        initialValues={getDefaultValues()}
        onSubmit={handleSubmit}
        validateOnMount
        validateOnChange
        validateOnBlur
      >
        {(formikProps) => (
          <Form className={styles.form}>
            <Stack alignItems="center" spacing={[3, 3, 3, 5]}>
              <Stepper
                steps={steps}
                completed={completed}
                activeStep={activeStep}
                onStepChange={handleStepChange(formikProps.errors)}
              />
              <div className={styles.form}>
                <Grid
                  container
                  columnSpacing={[2, 3, 3, 6]}
                  rowSpacing={2}
                  className={innerClass}
                  width={1}
                >
                  {fields
                    .filter((field) => field.step === activeStep)
                    .map((field, index) => {
                      let GridProps = getGridProps(field);
                      return (
                        <Grid
                          item
                          {...GridProps}
                          key={field.name}
                          className={`item${index + 1}`}
                        >
                          {getComponent(field, formikProps)}
                        </Grid>
                      );
                    })}
                  {matchesMdDown && activeStep === steps.length - 1 && (
                    <Grid item xs={12}>
                      {Recaptcha(formikProps)}
                    </Grid>
                  )}
                </Grid>
              </div>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
                width={1}
              >
                <Button
                  style="btn__white"
                  onClick={handleBack(formikProps.errors)}
                >
                  <p className="btn_text">{formatMessage({ id: 'back' })}</p>
                </Button>
                {!matchesMdDown &&
                  activeStep === steps.length - 1 &&
                  Recaptcha(formikProps)}
                <Button
                  type={activeStep === steps.length - 1 ? 'submit' : 'button'}
                  style={loading ? styles.container__loading : null}
                  onClick={handleAddStep(formikProps)}
                >
                  {loading ? (
                    <Spinner isLoading={loading} />
                  ) : (
                    <p className="btn_text">
                      {formatMessage({
                        id: activeStep === steps.length - 1 ? 'submit' : 'next',
                      })}
                    </p>
                  )}
                </Button>
              </Stack>
            </Stack>
          </Form>
        )}
      </Formik>
      <Snackbar
        open={!!snackbar}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar?.type}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbar?.text}
        </Alert>
      </Snackbar>
    </>
  );
};

StepForm.propTypes = {
  steps: PropTypes.array.isRequired,
  fields: PropTypes.array.isRequired,
  schema: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  submitted: PropTypes.bool,
  setSubmitted: PropTypes.func,
  loading: PropTypes.bool,
  snackbar: PropTypes.object,
  handleCloseSnackbar: PropTypes.func,
  maxWidth: PropTypes.string,
  innerClass: PropTypes.string,
  intl: PropTypes.object.isRequired,
};

export default injectIntl(StepForm);
