// extracted by mini-css-extract-plugin
export var Input = "style-module__Input___FeCaQ";
export var Input__formControl = "style-module__Input__formControl___GgrnO";
export var Input__input = "style-module__Input__input___pRLtP";
export var NewOption = "style-module__NewOption___HkYBE";
export var background_images = "style-module__background_images___JjUCY";
export var body_background = "#f8f9fa";
export var card = "style-module__card___JF-SG";
export var checkbox = "style-module__checkbox___-EpdG";
export var checkboxLabel = "style-module__checkboxLabel___sCsQd";
export var chip = "style-module__chip___XPPry";
export var container = "style-module__container___LqN+A";
export var container_captcha = "style-module__container_captcha___V6OXo";
export var content = "style-module__content___RnDOj";
export var error = "style-module__error___0IqKe";
export var even_second_color = "#557da1";
export var font_family = "-apple-system,BlinkMacSystemFont,\"Segoe UI\",Roboto,\"Helvetica Neue\",Arial,\"Noto Sans\",sans-serif,\"Apple Color Emoji\",\"Segoe UI Emoji\",\"Segoe UI Symbol\",\"Noto Color Emoji\"";
export var form = "style-module__form___BenLp";
export var formControl = "style-module__formControl___nKGGn";
export var full_img = "style-module__full_img___7LCg1";
export var full_imgSmall = "style-module__full_img--small___83Naf";
export var gray_21 = "style-module__gray_21___BKahK";
export var image_container = "style-module__image_container___1c+Wi";
export var image_item = "style-module__image_item___LR1vA";
export var lg = "1200px";
export var logo = "style-module__logo___IGOM9";
export var md = "900px";
export var paper_background = "#fff";
export var primary = "style-module__primary___3Rfnf";
export var primary_light = "#e0f9f3";
export var radio = "style-module__radio___LnP-e";
export var radioLabel = "style-module__radioLabel___BkFyV";
export var secondary = "#868d96";
export var sm = "600px";
export var spacing = "8";
export var title = "style-module__title___cz+d2";
export var typography_h1 = "style-module__typography_h1___t8eYM";
export var typography_h2 = "style-module__typography_h2___kQiPt";
export var typography_h3 = "style-module__typography_h3___iBP45";
export var white = "#fff";
export var white_button = "style-module__white_button___QgR0a";
export var xl = "1536px";
export var xxl = "2500px";