import { call, put, takeLatest } from 'redux-saga/effects';

import { createProfessionals } from '../../services/professionals';
import ActionHelper from '../../utils/action-helper';
import PROFESSIONAL_ACTION_TYPES from '../actions/PROFESSIONAL_ACTION_TYPES';

/**
 * Create ProfessionalRequest to backend
 */
export function* createProfessionalEntity({ payload: input }) {
  try {
    const response = yield call(createProfessionals, input);
    yield put(
      ActionHelper.fire(PROFESSIONAL_ACTION_TYPES.ADD_SUCCESS, response)
    );
  } catch (error) {
    console.log('Error posting professionalRequest:', error);
    yield put(
      ActionHelper.fire(PROFESSIONAL_ACTION_TYPES.ADD_FAILURE, error.toString())
    );
  }
}

export default function* professionalAdd() {
  yield takeLatest(
    PROFESSIONAL_ACTION_TYPES.ADD_REQUEST,
    createProfessionalEntity
  );
}
