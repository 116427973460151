import React from 'react';
import PropTypes from 'prop-types';
import FormikInput from './formikInput';
import { Stack } from '@mui/material';

const FormRange = (props) => {
  return (
    <Stack
      direction="row"
      spacing={1}
      useFlexGap
      flexWrap="nowrap"
      alignItems="center"
    >
      <div style={{ flexGrow: 1 }}>
        <FormikInput name={props.name[0]} label={props.label[0]} />
      </div>
      -
      <div style={{ flexGrow: 1 }}>
        <FormikInput name={props.name[1]} label={props.label[1]} />
      </div>
    </Stack>
  );
};

FormRange.propTypes = {
  name: PropTypes.array.isRequired,
  label: PropTypes.array.isRequired,
};

export default FormRange;
